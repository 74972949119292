<template>
  <div class="contant">
    <div class="flex flex_b">
      <div class="addTile">编辑部门</div>
    </div>
    <div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="部门名称">
          <el-input v-model="form.name" placeholder="部门名称"></el-input>
        </el-form-item>
      </el-form>
      <div class="btnSave" @click="edit">
        确定
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'newStaff',
  data() {
    return {
      form: {
        name: '',
      },
      id: '',
    }
  },
  created() {
    let data = JSON.parse(this.$route.query.data)
    this.form = data
  },
  methods: {
    edit() {
      let _that = this
      if (!this.form.name) {
        _that.utils.error('请输入部门名称')
        return
      }
      let data = this.form
      _that.$api.updateDept(data).then((res) => {
        if (res.data.code == 200) {
          _that.$router.go(-1)
          _that.utils.success('编辑成功')
        } else {
          this.utils.error(res.data.message)
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.addZw {
  width: 20px;
  height: 20px;
  line-height: 19px;
  border-radius: 50%;
  font-size: 16px;
  color: #fff;
  background: #f39517;
  text-align: center;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
  user-select: none;
}
</style>
